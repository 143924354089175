<template>
    <div class="pin-authentication">
        <radar-spinner v-if="loading" :animation-duration="2000" :size="60" color="#343a40" />
        <template v-else>
            <b-alert v-if="terminalId == null || terminalId == ''" variant="danger" show>Please scan a valid QR-Code</b-alert>
            <template v-else>
                <span class="pin-explain">Please enter the pin shown in the terminal</span>
                <div class="pin-inputs-wrapper">
                    <div class="pin-inputs">
                        <input class="pin-input" ref="input-0" type="tel" v-model="pinInputs[0]" maxlength="1" @keyup="focusOnInput($event, 1)"/>
                        <input class="pin-input" ref="input-1" type="tel" v-model="pinInputs[1]" maxlength="1" @keyup="focusOnInput($event, 2)"/>
                        <input class="pin-input" ref="input-2" type="tel" v-model="pinInputs[2]" maxlength="1" @keyup="focusOnInput($event, 3)" />
                        <input class="pin-input" ref="input-3" type="tel" v-model="pinInputs[3]" maxlength="1" @keyup="focusOnInput($event, 4)"/>
                    </div>
                    <b-button class="my-3" @click="validPin()">Submit</b-button>
                </div>
            </template>
            <b-alert v-if="!pinIsValid" variant="danger" show>The PIN code you typed is incorrect</b-alert>
        </template>
    </div>
</template>
<script>
    import { RadarSpinner  } from 'epic-spinners'

    export default {
        components: {RadarSpinner},
        data(){
            return {
                terminalId: null,
                loading: true,
                pin : null,
                pinInputs: [],
                pinIsValid: true
            }
        },
        mounted(){
            this.terminalId = this.$route.query.tId
            if(this.terminalId && this.terminalId !== ""){
                this.pin = this.generatePin()
                this.$mqtt.initMqttClient(() => {
                    let message = {
                        passCode: this.pin
                    }
                    let topic = this.$mqtt.topics.LAUNCH_POPIN_TOPIC.format({terminalId: this.terminalId})
                    this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                        this.loading = false
                    })
                });
            }else{
                this.loading = false
            }
        },
        methods: {
            generatePin(){
                let pin = parseInt(Math.random() * 10000);
                pin = '0' + pin
                return pin.slice(-4)
            },
            validPin(){
                if(this.typedPin === this.pin){
                    this.pinIsValid = true
                    this.loading = true
                    setTimeout(() => {
                        localStorage.setItem("authenticationDone", true)
                        this.$router.push({name: "roles", query: {tId: this.terminalId}})
                    }, 2000)
                }else{
                    this.pinIsValid = false
                }
            },
            focusOnInput(e, inputId){
                if(e.keyCode != 8 && e.keyCode != 37 && e.keyCode != 46){
                    if(inputId == 4){
                        this.validPin()
                    }else{ 
                        let inputToFocus = this.$refs['input-' + inputId]
                        if(inputToFocus){
                            inputToFocus.focus()
                        }
                    }
                }else{
                    let inputToFocus = this.$refs['input-' + (inputId - 2)]
                    console.log((inputId - 2))
                    if(inputToFocus){
                        inputToFocus.focus()
                    }
                }
            }
        },
        computed:{ 
            typedPin(){
                if(this.pinInputs.length == 0){
                    return null
                }
                return this.pinInputs.reduce((accumulator, currentValue) => accumulator + currentValue)
            }
        }
    }
</script>